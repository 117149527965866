<template>
<!-- eslint-disable -->
	<div>
		<div class="mb-10">
			<div id="animation" style="position:absolute; top:250px; pointer-events: none;" :style="backColor()" />
			<h1 id="title" style="position:absolute; top:300px;">OASIS<br/>APP</h1>
			<v-carousel 
				class="pt-4"
				:show-arrows="false" 
				:continuous="false"
				hide-delimiters 
				height="100%"
			>
				<v-carousel-item>
					<h1 class="mt-5 ml-6">TIME LINE</h1>
					<v-btn @click="report()" fab :color="this.color" style="position: absolute !important; left: 310px !important; top: 20px;"><v-icon color="white">mdi-pencil</v-icon></v-btn>
					<v-row>
						<v-col cols="9">
							<DatePicker class="mt-4" style="padding: 0!important;" @changeDate="search"/>
						</v-col>
						<v-icon @click="load()" class="pb-3" style="margin-left: -40px;">mdi-close</v-icon>
						<v-icon @click="sortFlg = !sortFlg" class="pb-3" style="margin-left: 20px;">mdi-format-list-numbered</v-icon>
					</v-row>
					<v-row v-if="sortFlg" style="position: absolute; right: 20px; z-index: 999; margin-top: -12px!important;">
						<v-card>
							<v-card-text @click="sort('newer')">新しい順</v-card-text>
							<v-card-text @click="sort('older')">古い順</v-card-text>
							<v-card-text @click="sort('liker')">いいね順</v-card-text>
						</v-card>
					</v-row>
					<v-card
						v-for="item in this.viewList" :key="item.id"
						class="mx-auto mt-4"
						max-width="350"
						min-height="125"
						@click="detail(item)"
						:class="fire(item.fire)"
					>
						<v-card-text>
							<v-row>
								<h3 class="pl-1">{{ item.updated_at.slice(0, 10) }}</h3>
								<v-spacer></v-spacer>
								
								<!-- <span class="pr-2 pt-1" style="font-weight: bolder"><img style="max-width: 15px; border-radius: 50%;" :src="'icon/'+item.updated_img" alt="userImg"></span> -->
							
							</v-row>
							<div class="mt-8">
								<p class="content" style="white-space: pre-wrap;">{{ item.content }}</p>
							</div>
						</v-card-text>
					</v-card>
					<div class="text-center" style="margin: 20px auto; font-size: 12px !important">
						<v-pagination
						v-if="pageFlg"
						v-model="page"
                		:length="Math.ceil(this.list.length / 5)"
						:total-visible="5"
						:color="this.color"
						@input="load"
						></v-pagination>
					</div>
				</v-carousel-item>
				<v-carousel-item>	
					<h1 class="mt-5 ml-6">Favorite List</h1>
					<v-btn @click="report()" fab :color="this.color" style="position: absolute !important; left: 310px !important; top: 20px;"><v-icon color="white">mdi-pencil</v-icon></v-btn>
					<v-card
						v-for="item in this.viewFavList" :key="item.id"
						class="mx-auto mt-8"
						max-width="350"
						min-height="125"
						@click="detail(item)"
					>
						<v-card-text>
							<v-row>
								<h3 class="pl-1">{{ item.updated_at.slice(0, 10) }}</h3>
								<v-spacer></v-spacer>
								<span class="pr-2 pt-1" style="font-weight: bolder">{{ item.updated_by }}</span>
							</v-row>
							<div class="mt-8">
								<p class="content" style="white-space: pre-wrap;">{{ item.content }}</p>
							</div>
						</v-card-text>
					</v-card>
					<div class="text-center" style="margin: 20px auto; font-size: 12px !important">
						<v-pagination
						v-if="favPageFlg"
						v-model="favPage"
                		:length="Math.ceil(this.favList.length / 5)"
						:total-visible="5"
						:color="this.color"
						@input="load"
						></v-pagination>
					</div>
				</v-carousel-item>
			</v-carousel>
			<v-dialog
				v-model="rankResultFlg"
				max-width="90%"
			>
				<v-card class="gradation" height="300px">
					<div class="text-center pt-6 pb-6 pl-2">
						<h2 style="padding-bottom: 50px;">{{ this.month }}月ランキング結果</h2>
						<v-row v-for="(ranker, index) in this.rankList" :key="ranker.id" :class="rankStyle(index + 1)" class="mb-4">
							<h4 style="margin-left: 80px">
								<span v-if="index + 1 == 1" style="margin-right: 50px;"><v-icon>mdi-trophy</v-icon></span>
								<span v-if="index + 1 != 1" class="ml-1" style="margin-right: 60px;">{{ index + 1 }}</span>
								{{ ranker.name }}
							</h4>
							<span style="position: absolute; right: 7%; font-weight: bold;">{{ ranker.rank_cnt_m }}</span>
						</v-row>
					</div>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
/*eslint-disable */
import * as api from '@/api.js'
import { animate, timeline } from "motion"
import DatePicker from '@/components/DatePicker.vue'
export default {
	name: 'Home',
	components: { DatePicker },
	data () {
		return {
			list: [],
			favList: [],
			viewList: [],
			viewFavList: [],
            page: 1,
            favPage: 1,
            pageFlg: true,
            favPageFlg: true,
			batchFlg: false,
			// ソート
			sortFlg: false,
			order: '',
			// 検索
			searchUser: '',
			// 機能アップデート通知
			popFlg: false,
			// ランキング
			month: new Date().getMonth(),
			rankList: [],
			rankResultFlg: false,
		}
	},
	methods: {
		load() {
			this.sort()
			// ソート条件があったらソートして終了
			if(this.order) return
            this.$store.dispatch('nowLoading', true)
			api.home.get().then((res) => {
                switch(res.data.status) {
                    case 200:
						this.list = res.data.content.Reports

						if(this.$route.params.page) {
							this.page = this.$route.params.page
							this.$route.params.page = 0
						}

						
						this.viewList = this.list.slice((this.page - 1 )* 5, this.page * 5)
						this.favList = res.data.content.FavList
						this.viewFavList = this.favList.slice((this.favPage - 1 )* 5, this.favPage * 5)
						if (this.list.length == 0) this.pageFlg = false
						if (this.favList.length == 0) this.favPageFlg = false

						if (res.data.content.mesList.length != 0) {
							this.batchFlg = true
						}
						else {
							this.batchFlg = false
						}
						this.$emit('getBatch', this.batchFlg)

                        break
                    case 500:
                        this.goToLogin()
                        break
                }
			}).finally(this.$store.dispatch('nowLoading', false))
		},
		search(date) {
			// emitは書き方特殊
			this.$store.dispatch('nowLoading', true)
			api.home.search({ date: date }).then((res) => {
                switch(res.data.status) {
                    case 200:
						this.list = res.data.content
						this.viewList = this.list.slice((this.page - 1 )* 5, this.page * 5)
                        break
                    case 500:
                        this.goToLogin()
                        break
                }
			}).finally(this.$store.dispatch('nowLoading', false))
		},
		sort(order) {
			// いま指定している並びがない且つ引数がない
			if (!this.order && !order) return
			if (order) this.order = order
			this.$store.dispatch('nowLoading', true)
			api.home.sort({ order: this.order }).then((res) => {
				switch(res.data.status) {
					case 200:
						this.list = res.data.content
						this.viewList = this.list.slice((this.page - 1 )* 5, this.page * 5)
						break
					case 500:
                        this.goToLogin()
						break
				}
			}).finally(this.$store.dispatch('nowLoading', false))
			this.sortFlg = false
		},
		rankResult() {
			let date = new Date().getDate()
			if (date == 1) {
				this.getRankList()

				// this.rankReset()
				// this.$store.dispatch('setOnce', false)

				return
			}
			this.rankResultFlg = false 
		},
		getRankList() {
            this.$store.dispatch('nowLoading', true)
			api.home.getRank().then((res) => {
				console.log(res)
				switch(res.data.status) {
					case 200:
						if(res.data){
							this.rankList = res.data.content
							this.rankResultFlg = true							
						}
						break
					case 500:
						break
				}
			}).finally(this.$store.dispatch('nowLoading', false))
		},
		rankStyle(rank){
			if (rank == 1) return 'first'
			if (rank == 2) return 'second'
			if (rank == 3) return 'third'
		},



		// rankReset() {
		// 	// let date = new Date().getDate()
		// 	// if (date == 1 && this.once) {


        //     	this.$store.dispatch('nowLoading', true)
		// 		api.home.rankReset().then((res) => {
		// 			switch(res.data.status) {
		// 				case 200:
		// 					console.log('月間ランキングカウントをリセットしました。')
		// 					break
		// 				case 500:
		// 					console.log('月間ランキングカウントリセットに失敗しました。')
		// 					break
		// 			}
		// 		}).finally(this.$store.dispatch('nowLoading', false))


		// 	// }
		// },



		report() {
			this.$router.push({name: 'Upload'})
		},
		detail(item) {
			console.log(this.page)
			this.$router.push({name: 'Detail', params: { content: item, page: this.page }})
		},
		backColor() {
			return 'background-color:' + this.color + ';'
		},

		fire(amount) {
			if(amount == 200) return 'golden'
			if(amount == 100) return 'silver'
			if(amount == 50) return 'bronze'
			return ''
		},

	},
	created(){
		this.load()
		this.rankResult()
	},
	mounted(){
		const scale = animate("#animation",{ scale: 15 },
			{ 
				duration: 1
			}
		)
		const hide = animate("#animation, #title",{ opacity: 0 },
			{ 
				duration: 2
			}
		)
		timeline(scale, hide, {})
	},
	computed: {

		// once() {
        //     return this.$store.getters.once
		// },

        color() {
            return this.$store.getters.loginInfo.color
        }
    },
}
</script>
<style>
.content{
	font-size: 16px;
}
#animation {
	position: absolute;
	top: 50vh;
	left: 40%;
	border-radius: 100px;
	height:100px;
	width: 100px;
	z-index: 1;
}
#title{
	font-size: 50px;
	color: white;
	position: absolute;
	top: 30%;
	left: 35%;
	z-index: 1;
}
.first {
	background-color: #e6b422;
	font-size: 26px;
	width: 80%;
}
.second {
	background-color: #c9caca;
	font-size: 23px;
	width: 90%;
}
.third {
	background-color: #a17136;
	font-size: 20px;
	width: 100%;
}

.golden {
	color: rgba(0, 0, 0, 0.72);
	text-decoration: none;
	font-weight: bold;
	text-shadow: 1px 1px 0 #ffffff;
	box-shadow:
	2px 2px 0.5em rgba(122, 98, 0, 0.55),
	inset 1px 1px 0 rgba(255, 255, 255, 0.9),
	inset -1px -1px 0 rgba(0, 0, 0, 0.34)
	;
	border: 1px solid #deca73;
  	background: linear-gradient(-72deg,#ffde45,#fff 16%,#ffde45 21%,#fff 24%,#452100 27%,#ffde45 36%,#fff 45%,#fff 60%,#ffde45 72%,#fff 80%,#ffde45 84%)

}
.silver {
	color: rgba(0, 0, 0, 0.5);
	text-decoration: none;
	font-weight: bold;
	text-shadow: 1px 1px 0 #ffffff;
	box-shadow:
	2px 2px 0.5em rgba(122, 122, 122, 0.55),
	inset 1px 1px 0 rgba(255, 255, 255, 0.9),
	inset -1px -1px 0 rgba(0, 0, 0, 0.34)
	;
	border: 1px solid #dedede;
	background: linear-gradient(-72deg,#dedede,#fff 16%,#dedede 21%,#fff 24%,#454545 27%,#dedede 36%,#fff 45%,#fff 60%,#dedede 72%,#fff 80%,#dedede 84%,#a1a1a1)
}
.bronze {
  color: rgba(45, 00, 0, 0.55);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #dea173;
  box-shadow:
    2px 2px 0.5em rgba(122, 55, 34, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5)
  ;
  border: 1px solid #dea173;
  background: linear-gradient(-72deg,#ca7345,#ffdeca 16%,#ca7345 21%,#ffdeca 24%,#a14521 27%,#ca7345 36%,#ffdeca 45%,#ffdeca 60%,#ca7345 72%,#ffdeca 80%,#ca7345)
}
</style>